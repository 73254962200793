<template>
  <div class="page half-width">
    <div class="page-content">
      <v-row>
        <v-col cols="7">
          <div class="page-header">
            <div class="page-header_left">
              <div class="page-header_back-link" @click="$router.back()">
                <div class="icon icon-arrow"></div>
                <span>Назад</span>
              </div>
              <div class="page-title">
                Собеседование
                <span v-if="candidate">
                  c <router-link :to="`/candidates/candidate/${candidate.id}`">
                  {{ candidate.name }} {{ candidate.surname }}
                </router-link>
                </span>
              </div>
              <div class="page-subtitle" v-if="interview">
                <span class="color-red" v-if="timer.on">Идет сейчас</span>
                <span v-else>
            {{
                    $moment(headerDate)
                      .locale('ru')
                      .format('DD MMMM HH:mm')
                  }}
            </span>
              </div>
            </div>
          </div>
          <div class="interview-head">
            <div class="interview-place-info" v-if="placeInfoStr">
              {{ placeInfoStr }}
            </div>
            <div class="interview-timer" v-if="interview.id">
              <div class="interview-timer_time">
                <span :class="{'color-red' : timer.on}">{{ getTimeForTimer(timer.time) }}</span>
              </div>
              <template v-if="isCompleted">
                <span>Завершено</span>
              </template>
              <div v-else class="interview-timer_buttons">
                <div class="row-buttons" v-if="timer.on">
                  <div class="default-button" @click="$refs['confirm-complete'].open()">
                    Завершить собеседование
                  </div>
                  <div
                    class="cancel-button"
                    @click="$refs['confirm-reset-timer'].open()"
                  >
                    Сбросить таймер
                  </div>
                </div>
                <div class="row-buttons" v-else>
                  <div class="default-button" @click="startTimerButtonClick">Начать</div>
                  <div class="cancel-button"
                       @click="$eventBus.emit('create-event-modal-open', {event_id: interview.id})">Перенести
                  </div>
                  <div class="white-button" @click="$refs['confirm-cancel-event'].open()">Отменить</div>
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="4" class="interview-additional-info">
          <div class="candidate-avatar" v-if="candidate">
            <CandidateMedia :candidate="candidate"/>
          </div>
          <router-link :to="`/vacancies/vacancy/${interview.vacancy.id}`" class="vacancy-info" v-if="interview.vacancy && interview.vacancy.id !== '0'">
            Вакансия&nbsp;<span>{{interview.vacancy.title}}</span>
          </router-link>

        </v-col>
      </v-row>

      <v-col cols="7" class="pl-0">
        <v-tabs v-model="selectedTab">
          <v-tab :ripple="false">Резюме</v-tab>
          <v-tab :ripple="false">Заметки</v-tab>
          <v-tab :ripple="false">Скрипт</v-tab>
        </v-tabs>
      </v-col>
      <v-col :cols="selectedTab === 0 ? 12 : 7" class="page-body pa-0">
        <v-tabs-items v-model="selectedTab">
          <v-tab-item>
            <ResumeTab :candidate="candidate" :resume-html="resumeHtml" @resume-loaded="loadEvent" @on-resume-update="loadEvent"/>
          </v-tab-item>
          <v-tab-item>
            <v-btn text color="blue" class="px-0" @click="updateNote" depressed>Сохранить</v-btn>
            <vue-editor v-model="interview.comment"/>
          </v-tab-item>
          <v-tab-item>
            <div class="adding-item" v-if="Number(interview.form?.id)">
              <div class="adding-item_text">{{ interview.form.title }}</div>
              <div
                class="adding-item_icon icon icon-close"
                @click="$refs['confirm-unbind-script'].open()"
              />
            </div>
            <div
              class="adding-item"
              @click="$refs['select-script-modal'].open()"
              v-else
            >
              <div class="adding-item_text">Прикрепить скрипт</div>
              <div class="adding-item_icon icon icon-new"></div>
            </div>
            <div class="question-list" v-if="questions.length">
              <ul>
                <li v-for="(question, index) in questions" :key="index">
                  <div class="question-list-item">
                    <div class="question-list-item_before">
                      <div class="question-list-item_number">{{ index + 1 }}</div>
                    </div>
                    <div class="question-list-item_inner">
                      <div class="question-list-item_question">{{ question.question }}</div>
                      <div class="question-list-item_options" v-if="question.options.length">
                        <div class="question-list-item_option"
                             v-for="(option, index) in question.options"
                             :key="index"
                        >
                          <v-checkbox
                            @change="updateQuestions"
                            v-model="option.checked"
                            :true-value="true"
                            :false-value="false"
                            :id="'answer'+index"
                            :label="option.name"
                            hide-details="auto"
                          />
                        </div>
                      </div>
                      <Field
                        :value="question.answer"
                        classes="question-list-item_answer"
                        placeholder="Введите текст.."
                        @update:modelValue="question.answer = $event"
                        @on-change="updateQuestions"
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </div>
    <div class="page-right-content">

      <!--      <CandidateChainEvents v-if="chainEvents.length" :events="chainEvents" :editable="false"/>-->
    </div>
    <SelectScriptsModal ref="select-script-modal" @submit="bindScript"/>
    <Confirm ref="confirm-unbind-script" title="Открепить скрипт?" @accept="unbindScript" accept-text="Да" cancel-text="Нет"/>
    <Confirm ref="confirm-cancel-event" cancel-text="Нет" accept-text="Да" title="Отменить собеседование?" @accept="cancelEvent"/>
    <Confirm ref="confirm-reset-timer" title="Сбросить таймер?" @accept="resetTimer" cancel-text="Нет" accept-text="Да"/>
    <Confirm ref="confirm-complete" title="Завершить собеседование?" @accept="completeInterview"/>
  </div>
</template>

<script>
import CandidateChainEvents from '@/views/candidate/CandidateChainEvents.vue';
import SelectScriptsModal from '@/views/scripts/SelectScriptsModal.vue';
import ResumeTab from '@/components/ResumeTab';
import Field from '@/components/Field';
import CandidateMedia from '@/views/candidate/CandidateMedia';
export default {
  name: 'Interview',
  components: {
    SelectScriptsModal,
    CandidateChainEvents,
    ResumeTab,
    Field,
    CandidateMedia
  },
  data() {
    return {
      interview: {},
      questions: [],
      resumeHtml: {},
      place: {},
      candidate: {},
      timer: {
        on: false,
        time: 0,
      },
      selectedTab: 0,
      tabsName: ['resume', 'note', 'script']
    };
  },
  methods: {
    startTimerButtonClick() {
      this.$server.request2('event/startTimer', { event: this.interview.id }, () => {
        this.startTimer();
        this.loadEvent();
      });
    },
    startTimer() {
      this.timer.on = true;
      this.timer.interval = setInterval(() => {
        this.timer.time += 1000;
      }, 1000);
    },
    resetTimer() {
      this.$server.request2('event/resetTimer', { event: this.interview.id }, () => {
        this.timer.on = false;
        this.timer.time = 0;
        clearInterval(this.timer.interval);
        this.loadEvent();
      });
    },
    endTimer() {
      this.$server.request2('event/endTimer', { event: this.interview.id }, () => {
        this.timer.on = false;
        clearInterval(this.timer.interval);
        this.loadEvent();
      });
    },
    completeInterview() {
      this.$server.request2(`event/update/${this.interview.id}`, { status: '1' }, () => {
        if (this.timer.on) {
          this.endTimer();
        }
        this.$eventBus.emit('not-main-event-modal-open', {
          id: this.interview.id,
          title: 'Оценка собеседования'
        });
      });
    },
    getTimeForTimer(time) {
      const allSeconds = String(time)
        .slice(0, -3);

      const hours = Math.floor(allSeconds / 3600);
      const minutes = Math.floor((allSeconds - (hours * 3600)) / 60);
      const seconds = Math.floor(allSeconds - (hours * 3600) - (minutes * 60));
      return hours ? `${hours}:${(`0${minutes}`).slice(-2)}:${(`0${seconds}`).slice(-2)}` : `${(`0${minutes}`).slice(-2)}:${(`0${seconds}`).slice(-2)}`;
    },
    bindScript(script) {
      this.$server.request2(`event/update/${this.interview.id}`, { form: script.id }, (data) => {
        this.loadEvent();
      });
    },
    unbindScript() {
      this.$server.request2(`event/update/${this.interview.id}`, {
        form: 0,
        questions: null
      }, () => {
        this.loadEvent();
      });
    },
    cancelEvent() {
      this.$server.request2(`event/remove/${this.interview.id}`, {}, (data) => {
        this.$router.replace({ name: 'Calendar' });
      });
    },
    setQuestionsData() {
      if (this.interview.questions) {
        this.questions = this.interview.questions.map((question) => ({
          ...question,
          options: (question.options || []).map((option) => ({
            name: option.name,
            checked: option.checked,
          })),
        }));
      } else if (this.interview.form?.questions) {
        let questions = this.interview.form.questions;
        this.questions = questions.map((question) => ({
          question: question.question,
          options: question.options
            ? question.options.map((option) => ({
              name: option,
              checked: option.checked,
            }))
            : [],
          answer: '',
        }));
      } else {
        this.questions = [];
      }
    },
    setDataFromScript() {
      this.setQuestionsData();
      if (Number(this.interview.datetime_start)) {
        if (Number(this.interview.datetime_end)) {
          clearInterval(this.timer.interval);
          this.timer.time = Number(`${this.interview.datetime_end - this.interview.datetime_start}000`);
        } else {
          this.startTimer();
          this.timer.time = Date.now() - Number(`${this.interview.datetime_start}000`);
        }
      }
    },
    updateQuestions() {
      this.$server.request2(`event/update/${this.interview.id}`, { questions: this.questions }, () => {
        this.loadEvent();
      });
    },
    loadResume() {
      const inputFile = document.createElement('input');
      inputFile.setAttribute('type', 'file');
      inputFile.addEventListener('change', (e) => {
        if (e.target.files.length) {
          this.$eventBus.emit('open-loader-modal');
          this.$server.uploadFile(e.target.files[0], {
            type: 'candidate',
            parse: 1,
          })
            .then(({ response }) => {
              this.$server.request2(`candidate/update/${this.candidate.id}`, { file: response.id }, () => {
                this.loadItem();
                this.$eventBus.emit('close-loader-modal');
              });
            });
        }
      });
      inputFile.click();
    },
    loadEvent() {
      if (this.timer.on) clearInterval(this.timer.interval);
      this.$server.request2(`event/get/${this.$route.params.eventId}`, {}, (data) => {
        this.interview = data.response;
        if (data.response.place) this.place = data.response.place;
        if (data.response.candidate) {
          this.candidate = data.response.candidate;
          if (data.response.candidate.file) this.loadResumeHtml(data.response.candidate.file.id);
        }
        this.setDataFromScript();
      });
    },
    loadResumeHtml(id) {
      this.$server.request2('files/getHtml', {
        id,
        candidate: this.candidate.id
      }, (data) => {
        this.resumeHtml = data.response;
      });
    },
    setQueryTab(tabIndex) {
      this.$router.replace({
        path: `/interview/${this.$route.params.eventId}`,
        query: {tab: this.tabsName[tabIndex]}}).catch(e=>{});
    },
    updateNote() {
      this.$server.request2(`event/update/${this.interview.id}`, {comment: this.interview.comment}, ()=>{
        this.loadEvent();
      });
    }
  },
  computed: {
    headerDate() {
      if (this.interview) {
        if (this.interview.datetime_from) {
          return Number(`${this.interview.datetime_from}000`);
        }
        return this.interview.date;
      }
      return 0;
    },
    placeInfoStr() {
      if (this.interview.type === '0') {
        return this.place?.title;
      }
      return this.interview.event_link;
    },
    chainEvents() {
      return this.$store.getters.getEvents((event) => (this.candidate?.events || [])
        .includes(event.id));
    },
    isCompleted() {
      return Number(this.interview.datetime_start) && Number(this.interview.datetime_end);
    },
  },
  created() {
    this.$eventBus.on('event-created', () => {
      this.loadEvent();
    });
  },
  watch: {
    selectedTab(newTab) {
      this.setQueryTab(newTab);
    }
  },
  mounted() {
    this.loadEvent();
    const queryTab = this.$route.query.tab;
    if (queryTab) {
      this.selectedTab = this.tabsName.indexOf(queryTab);
    } else {
      this.setQueryTab(this.selectedTab);
    }
  },
  beforeDestroy() {
    if (this.timer.on) clearInterval(this.timer.interval);
    this.$eventBus.off('event-created');

  },
};
</script>

<style scoped lang="scss">
.page-title {
  font-size: 22px;
  line-height: 24px;
}

.page-subtitle {
  margin-top: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.page-right-content {
  width: 286px;
}

.page-header {
  margin-bottom: 18px;
}

.candidate-avatar {
  position: relative;
  width: 360px;

  .candidate-media {
    width: 100%;
    height: 210px;
    border-radius: 12px;

    &::v-deep(.no-image) {
      font-size: 38px;
    }
  }
}

.interview-head {
  margin-bottom: 74px;

  & > * {
    margin-bottom: 28px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.interview-place-info {
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  line-height: 20px;
  text-decoration: underline;
}

.interview-additional-info {
  .vacancy-info {
    width: 100%;
    height: 48px;
    padding: 0 12px;
    margin-top: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #F4F3F2;
    border-radius: 6px;
    cursor: pointer;
    color: var(--page-color-main);
    font-weight: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    span {
      font-weight: bold;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.interview-timer {
  display: flex;
  align-items: center;

  &_time {
    flex-shrink: 0;
    min-width: 94px;
    font-size: 22px;
    line-height: 32px;
    font-weight: bold;
    letter-spacing: -0.5px;
    color: rgba(var(--page-color-main-rgb), .25);
  }

  &_buttons {
    width: 100%;
  }
}

.tab {
  margin-top: 28px;
}

.adding-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F4F3F2;
  border-radius: 6px;
  cursor: pointer;

  &_text {
    margin-left: 22px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  &_icon {
    cursor: pointer;
    font-size: 15px;
    padding: 22px;
    font-weight: 600;
  }
}

.question-list {
  margin-top: 28px;

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  li {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.question-list-item {
  display: flex;

  &_before {
    flex-shrink: 0;
    margin-right: 24px;
  }

  &_number {
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--border-color);
    border-radius: 50%;
  }

  &_inner {
    width: 100%;
    display: flex;
    flex-direction: column;

    & > * {
      margin-bottom: 18px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_question {
    font-size: 14px;
    line-height: 20px;
    color: rgba(var(--page-color-main-rgb), .5);
  }

  &_options {
    & > * {
      margin-bottom: 14px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_option {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;

    .checkbox {
      margin-right: 12px;
    }
  }
}

.interview-note {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  min-height: 120px;
  height: 100%;
}

.candidate-resume-block {
  margin-top: 28px;

  &_head {
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
      font-size: 24px;
      color: rgba(var(--page-color-main-rgb), .25);
      flex-shrink: 0;
      margin-right: 12px;
    }

    span {
      display: block;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.candidate-chain-list {
  background: #FFFFFF;
}
</style>
