<template>
  <div class="candidate-chain-list">
    <ul>
      <li v-for="( event, index) in events" :key="index" :ref="`candidate_chain_event_${event.id}`">
        <div
          class="candidate-chain-item"
          @click="isSharedByToken ? ()=>{} : eventOnClick(event)"
        >
          <div class="candidate-chain-item_link">
            <div class="candidate-chain-item_link_icon">
              <div class="icon-wrap">
                <div class="icon"></div>
              </div>
            </div>
          </div>
          <div class="candidate-chain-item_inner">
            <div class="candidate-chain-item_inner_row">
              <div class="candidate-chain-item_title">
                <span
                  v-if="String(event.type) === '0' || String(event.type) === '1'"
                  class="bold"
                >
                  Собеседование
                </span>
                <span
                  v-else-if="String(event.type) === '2'"
                  class="bold"
                >
                  Напоминание
                </span>
                <span class="bold" v-else>{{ event.title }}</span>
                <EventRating :rating="event.rate" v-if="+event.rate"/>
              </div>
            </div>
            <div class="candidate-chain-item_inner_row" v-if="event.datetime_notify && event.type === '2'">
              <div
                class="candidate-chain-item_subtitle"
                v-html="getTimeStr(Number(`${event.datetime_notify}000`), event.type)"
              >
              </div>
            </div>
            <div class="candidate-chain-item_inner_row" v-else-if="event.datetime_from">
              <div class="candidate-chain-item_subtitle" v-html="
`${getTimeStr(Number(`${event.datetime_from}000`), event.type)} ${['', '0'].includes(event.datetime_to) ? ` - ${$moment(Number(`${event.datetime_to}000`))
                .format('HH:mm')}` : ''}`
">
              </div>
            </div>
            <div class="candidate-chain-item_inner_row" v-if="event.comment">
              <div class="event-list-item_comment">
                <div class="icon icon-comment"></div>
                <div class="event-list-item_comment-container"
                     :class="event.isFullComment ? 'full-comment' : 'small-comment'">
                  <div
                    :ref="`event-comment-${event.id}`"
                    class="event-comment-skeleton" v-html="event.comment"></div>
                  <span
                    v-html="event.comment"></span>
                </div>
              </div>
              <div
                v-if="event.boxHeight > 150"
                class="more-button color-blue" @click.stop="toggleComment(event)">
                {{ event.isFullComment ? 'Скрыть' : 'Подробнее' }}
              </div>
            </div>
          </div>
          <div class="candidate-chain-item_after"></div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import EventRating from '@/views/event/EventRating.vue';

export default {
  name: 'CandidateChainEvents',
  components: { EventRating },
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    events: {
      type: Array,
      default: () => [],
    },
    candidate: {
      type: Object,
      default: {},
    }
  },
  data() {
    return {
      isSharedByToken: this.$route.path.split('/').includes('shr'),
    }
  },
  methods: {
    eventOnClick(event) {
      if (this.editable) {
        if (['0', '1'].includes(event.type)) {
          this.$router.push(`/interview/${event.id}`);
        } else {
          this.$eventBus.emit('not-main-event-modal-open', { id: event.id });
        }
      }
    },
    getTimeStr(date, type = '0') {
      if (new Date(date).getFullYear() === new Date().getFullYear()) {
        const today = this.$moment()
          .format('YYYY-MM-DD');
        if (this.$moment(date)
          .format('YYYY-MM-DD') === today) {
          if (type === '3') {
            return `<span>Сегодня ${this.$moment(date)
              .format('HH:mm')}</span>`;
          } else {
            return `<span class="color-red">Сегодня ${this.$moment(date)
              .format('HH:mm')}</span>`;
          }
        }
        const tomorrow = this.$moment()
          .add(1, 'days')
          .format('YYYY-MM-DD');
        if (this.$moment(date)
          .format('YYYY-MM-DD') === tomorrow) {
          return `Завтра ${this.$moment(date)
            .format('HH:mm')}`;
        }
        return this.$moment(date)
          .locale('ru')
          .format('DD MMMM, ddd HH:mm');
      }
      return this.$moment(date)
        .locale('ru')
        .format('YYYY DD MMMM, ddd HH:mm');
    },
    toggleComment(event) {
      event.isFullComment = !event.isFullComment;
      this.$forceUpdate();
    },
  },
  created() {
    this.$eventBus.on('scroll-to-last-comment', (eventId) => {
      const chain_event = `candidate_chain_event_${eventId}`;
      this.$refs[chain_event][0].scrollIntoView({ behavior: 'smooth' });
      this.events.find(event => event.event.id === eventId).event.isFullComment = true;
      this.$forceUpdate();
    });
  },
  computed: {},
  beforeDestroy() {
    this.$eventBus.off('scroll-to-last-comment');
  },
  mounted() {
    if (this.events.length) {
      this.events.forEach((event) => {
        if (event.type === '3' && event.comment !== '') {
          event.boxHeight = this.$refs[`event-comment-${event.id}`][0].clientHeight;
          event.isFullComment = false;
          this.$forceUpdate();
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.candidate-chain-list {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    &:first-child {
      .candidate-chain-item_link:before {
        content: none;
      }
    }

    &:last-child {
      .candidate-chain-item_link:after {
        content: none;
      }
    }
  }
}

.candidate-chain-item {
  cursor: pointer;
  display: flex;

  &_link {
    position: relative;
    flex-shrink: 0;
    width: 20px;
    margin-right: 12px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    padding-top: 45px;

    &_icon {
      .icon-wrap {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: rgba(var(--page-color-main-rgb), .25);
      }

      .icon {
        color: #FFFFFF;
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      height: calc(100% - 65px);
      width: 1px;
      background: rgba(var(--page-color-main-rgb), .25);
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      height: 45px;
      width: 1px;
      background: rgba(var(--page-color-main-rgb), .25);
    }
  }

  &_inner {
    flex: 1;
    min-width: 0;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    color: rgba(var(--page-color-main-rgb), 1);

    &_row {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .event-list-item_comment {
        display: flex;
        align-items: flex-start;

        span {
          white-space: pre-wrap;
        }

        &-container.full-comment {
          max-height: 100%;
          height: fit-content;
        }

        &-container {
          position: relative;
        }

        &-container {
          .event-comment-skeleton {
            position: absolute;
            opacity: 0;
          }
        }

        &-container.small-comment {
          max-height: 150px;
          overflow: hidden;
        }
      }

      .more-button {
        font-size: 13px;
        margin-top: 10px;
        margin-left: 23px;
      }
    }

    & > * {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: inline-flex;
    align-items: center;

    .event-rating {
      margin-left: 10px;
    }
  }

  &.next {
    .candidate-chain-item {
      &_title {
        color: var(--page-color-main);
      }

      &_link {
        .icon-wrap {
          width: 22px;
          height: 22px;
          border: 2.5px solid var(--page-color-main);
          background: transparent;
        }

        .icon {
          width: 14px;
          height: 14px;
          background: var(--page-color-main);
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
