<template>
<div class="resume-container">
  <div class="candidate-resume-block" v-if="candidate.file && Number(candidate.file.id)">
    <div class="candidate-resume-block-links">
      <PopoverList :control-buttons="downloadResumeButtons" :is-slot="true" :width="300" activator-styles="display: flex">
        <template v-slot:activator>
          <v-btn text depressed class="pa-0 mr-3" style="height: fit-content" min-height="0">
            <v-icon size="14" class="mr-1">mdi-download</v-icon>
            <span class="color-blue">Скачать</span>
          </v-btn>
        </template>
      </PopoverList>
      <a v-if="candidate.source"
         class="candidate-resume-block_head"
         target="_blank" :href="'https://'+candidate.source">
        <v-icon size="18" class="mr-1">mdi-web</v-icon>
        <span class="color-blue">Источник</span>
      </a>
      <v-btn v-if="candidate.access_type !== 'read'"  text depressed class="pa-0 mr-3" style="height: fit-content" min-height="0" @click="loadResume">
        <v-icon size="18" class="mr-1">mdi-swap-horizontal</v-icon>
        <span class="color-blue">Заменить файл</span>
      </v-btn>
      <v-btn v-if="candidate.access_type !== 'read'"  text depressed class="pa-0 mr-3" style="height: fit-content" min-height="0" @click="editResume">
        <v-icon size="18" class="mr-1">mdi-pencil</v-icon>
        <span class="color-blue">Редактировать резюме</span>
      </v-btn>
    </div>
    <div class="resume-wrap" v-html="resumeHtml.text_html"></div>
  </div>
  <div  class="candidate-resume-block" v-else-if="candidate.access_type !== 'read'">
    <div class="candidate-resume-block_head" @click="loadResume">
      <span class="color-blue">Прикрепить резюме</span>
    </div>
  </div>
  <EditResumeModal ref="edit_resume_modal" @on-update="onResumeUpdate"/>
  <Alert ref="alert_modal"/>
</div>
</template>

<script>
import PopoverList from '@/components/PopoverList';
import EditResumeModal from '@/views/candidate/EditResumeModal';
import Alert from '@/components/Alert';
export default {
  components: {
    Alert,
    EditResumeModal,
    PopoverList,
  },
  name: 'ResumeTab',
  props: ['candidate', 'resumeHtml'],
  emits: ['resume-loaded', 'on-resume-update'],
  methods: {
    editResume() {
      this.$refs.edit_resume_modal.open({resume: this.resumeHtml.text_html, candidate_id: this.candidate.id});
    },
    onResumeUpdate() {
      this.$emit('on-resume-update');
    },
    loadResume() {
      const inputFile = document.createElement('input');
      inputFile.setAttribute('type', 'file');
      inputFile.setAttribute('accept', '.pdf,.doc,.docx')
      inputFile.addEventListener('change', (e) => {
        if (e.target.files.length && e.target.files[0].size < 4000000) {
          this.$eventBus.emit('open-loader-modal');
          this.$server.uploadFile(e.target.files[0], {
            type: 'candidate',
            parse: 1,
          })
            .then(({ response }) => {
              this.$server.request2(`candidate/update/${this.candidate.id}`, { file: response.id }, ()=>{
                this.$emit('resume-loaded');
                this.$eventBus.emit('close-loader-modal');
              }, ()=> {
                this.$eventBus.emit('close-loader-modal');
              });
            }).catch(()=>{
            this.$eventBus.emit('close-loader-modal');
          })
        } else {
          this.$refs.alert_modal.open({title: 'Ошибка при загрузке резюме', error: 'Размер файла слишком большой'})
        }
      });
      inputFile.click();
    },
  },
  computed: {
    downloadResumeButtons() {
      let buttons = [];
      if (this.candidate.file.download) this.candidate.file.download.forEach((link)=>{
        buttons.push({
          text: link.name,
          icon: '',
          color: '',
          handler: () => {
            window.open(link.url, '_blank').focus();
          },
        },)
      });
      return buttons;
    }
  }
};
</script>

<style scoped lang="scss">
.candidate-resume-block {
  &-links {
    display: flex;
    align-items: flex-end;
    .candidate-resume-block_head {
      margin-right: 16px;
      align-items: flex-end;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &_head {
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
      font-size: 24px;
      color: rgba(var(--page-color-main-rgb), .25);
      flex-shrink: 0;
    }
    span {
      display: block;
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
    }
  }
  .resume-wrap {
    max-width: 1100px;
    overflow: hidden;
  }
}
</style>